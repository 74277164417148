// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "html,\nbody {\n  background: #f4f4f4;\n  margin: 0;\n  padding: 0; }\n\n.src-index__content--UgoF9 {\n  margin-bottom: 50px;\n  padding: 20px; }\n\n.src-index__add--xIHqq {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin: 20px 0;\n  color: var(--adm-color-success);\n  user-select: none; }\n  .src-index__add--xIHqq:active {\n    opacity: 0.1; }\n\n.src-index__operate--a14Rl {\n  position: fixed;\n  bottom: 0;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  margin-bottom: 10px;\n  padding: 10px; }\n", "",{"version":3,"sources":["webpack://./src/index.scss"],"names":[],"mappings":"AAAA;;EAEE,mBAAmB;EACnB,SAAS;EACT,UAAU,EAAA;;AAGZ;EACE,mBAAmB;EACnB,aAAa,EAAA;;AAGf;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,cAAc;EACd,+BAA+B;EAC/B,iBAAiB,EAAA;EAPnB;IASI,YAAY,EAAA;;AAIhB;EACE,eAAe;EACf,SAAS;EACT,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,WAAW;EACX,mBAAmB;EACnB,aAAa,EAAA","sourcesContent":["html,\nbody {\n  background: #f4f4f4;\n  margin: 0;\n  padding: 0;\n}\n\n.content {\n  margin-bottom: 50px;\n  padding: 20px;\n}\n\n.add {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  margin: 20px 0;\n  color: var(--adm-color-success);\n  user-select: none;\n  &:active {\n    opacity: 0.1;\n  }\n}\n\n.operate {\n  position: fixed;\n  bottom: 0;\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  width: 100%;\n  margin-bottom: 10px;\n  padding: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": "src-index__content--UgoF9",
	"add": "src-index__add--xIHqq",
	"operate": "src-index__operate--a14Rl"
};
export default ___CSS_LOADER_EXPORT___;
