// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-components-detailpopup-index__detail--__2BK .src-components-detailpopup-index__wrapper--s3M1I {\n  margin-bottom: 100px;\n  position: relative;\n  height: 100vh; }\n\n.src-components-detailpopup-index__detail--__2BK table {\n  border-collapse: collapse;\n  border: 1px solid gray; }\n  .src-components-detailpopup-index__detail--__2BK table td {\n    text-align: center; }\n  .src-components-detailpopup-index__detail--__2BK table .src-components-detailpopup-index__dateColumn--inSjF {\n    min-width: 80px; }\n\n.src-components-detailpopup-index__detail--__2BK .src-components-detailpopup-index__operate--JOK3f {\n  width: 100%;\n  display: flex;\n  justify-content: space-around;\n  align-items: center;\n  position: fixed;\n  bottom: 20px; }\n", "",{"version":3,"sources":["webpack://./src/components/detailpopup/index.scss"],"names":[],"mappings":"AAAA;EAEI,oBAAoB;EACpB,kBAAkB;EAClB,aAAa,EAAA;;AAJjB;EAOI,yBAAyB;EACzB,sBAAsB,EAAA;EAR1B;IAWM,kBAAkB,EAAA;EAXxB;IAcM,eAAe,EAAA;;AAdrB;EAmBI,WAAW;EACX,aAAa;EACb,6BAA6B;EAC7B,mBAAmB;EACnB,eAAe;EACf,YAAY,EAAA","sourcesContent":[".detail {\n  .wrapper {\n    margin-bottom: 100px;\n    position: relative;\n    height: 100vh;\n  }\n  table {\n    border-collapse: collapse;\n    border: 1px solid gray;\n\n    td {\n      text-align: center;\n    }\n    .dateColumn {\n      min-width: 80px;\n    }\n  }\n\n  .operate {\n    width: 100%;\n    display: flex;\n    justify-content: space-around;\n    align-items: center;\n    position: fixed;\n    bottom: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"detail": "src-components-detailpopup-index__detail--__2BK",
	"wrapper": "src-components-detailpopup-index__wrapper--s3M1I",
	"dateColumn": "src-components-detailpopup-index__dateColumn--inSjF",
	"operate": "src-components-detailpopup-index__operate--JOK3f"
};
export default ___CSS_LOADER_EXPORT___;
