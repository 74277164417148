// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@charset \"UTF-8\";\n.src-components-formitem-styles__formItem--iZ__G .adm-card-header {\n  border-bottom: 0; }\n\n.src-components-formitem-styles__formItem--iZ__G .adm-card-body {\n  padding: 0; }\n\n.src-components-formitem-styles__formItem--iZ__G .src-components-formitem-styles__moneyItem--r7rch .adm-list-item-content-main :first-child {\n  text-align: right; }\n  .src-components-formitem-styles__formItem--iZ__G .src-components-formitem-styles__moneyItem--r7rch .adm-list-item-content-main :first-child ::after {\n    content: '元';\n    color: #666;\n    margin-left: 10px; }\n", "",{"version":3,"sources":["webpack://./src/components/formitem/styles.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EAGM,gBAAgB,EAAA;;AAHtB;EAMM,UAAU,EAAA;;AANhB;EAcU,iBAAiB,EAAA;EAd3B;IAgBY,YAAS;IACT,WAAW;IACX,iBAAiB,EAAA","sourcesContent":[".formItem {\n  :global {\n    .adm-card-header {\n      border-bottom: 0;\n    }\n    .adm-card-body {\n      padding: 0;\n    }\n  }\n\n  .moneyItem {\n    :global {\n      .adm-list-item-content-main {\n        :first-child {\n          text-align: right;\n          ::after {\n            content: '元';\n            color: #666;\n            margin-left: 10px;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formItem": "src-components-formitem-styles__formItem--iZ__G",
	"moneyItem": "src-components-formitem-styles__moneyItem--r7rch"
};
export default ___CSS_LOADER_EXPORT___;
